// @mui material components
import Icon from "@mui/material/Icon";

const routes = [
  {
    name: "pages",
    icon: <Icon>dashboard</Icon>,
  },
];

export default routes;
