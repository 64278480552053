/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "components/Cards/RotatingCard";
import RotatingCardFront from "components/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "components/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront image={bgFront} title="Work of Scope" description="" />
              <RotatingCardBack
                image={bgBack}
                title="Work of Scope"
                description=""
                // action={{
                //   type: "internal",
                //   route: "/sections/page-sections/page-headers",
                // }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="I"
                  title="Scientific Integrity"
                  description="The Lab pursues the scientific integrity of its contents. While the Lab trusts its clients to bring the best products in good faith, the Lab has an obligation to carefully examine the products and notify the clients of the results."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="P"
                  title="Promptness"
                  description="The Lab strives for the smooth and rapid transition of the clients and their products to the global market. The Lab aims to achieve and maintain adequate speed on its deliverables and minimize delays in the process whenever possible."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="T"
                  title="Transparency"
                  description="The Lab keeps its bidirectional channels of communication with clients, and remains an advocate for its clients in the entire processing steps. The Lab leaves no hidden agenda in the entire process."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="C"
                  title="Confidentiality"
                  description="The Lab keeps the confidentiality of its products and processing steps."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
