import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function BuiltByDevelopers() {
  const bgImage =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          {/* <MKTypography variant="h4" color="white" fontWeight="bold">
            Piscatorlab
          </MKTypography> */}
          <MKTypography variant="h1" color="white" mb={1}>
            Vision Statement
          </MKTypography>
          <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
            The PISCATOR LAB (hereafter &apos;the Lab&apos;) primarily aims to achieve successful
            U.S. (and global) market penetration for various overseas healthcare companies. By that,
            the lab expects to facilitate the spread of scientific knowledge and technological
            advancement, and eventually bring improved health outcomes for the healthy and the sick
            in the U.S. and global population.
          </MKTypography>
          {/* <MKTypography
            component="a"
            href="https://www.creative-tim.com/learning-lab/react/overview/material-kit/"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            color="white"
            fontWeight="regular"
            sx={{
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Read docs <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
